import React from "react";
import "./Projects.css";
import { useState, useEffect, useMemo } from "react";
import OpenLayersMap from "./MapDashboard";
import SidebarDashboard from "./SidebarDashboard";
import "./MapDashboard.css";
import Dashboard from "./Dashboard";
import "./Dashboard.css";

const optionToImagesMap = {
  option1: [
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 125.png",
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 126.png",
  ],
  option2: [
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 127.png",
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 128.png",
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 129.png",
  ],
  option3: [
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 130.png",
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 131.png",
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 132.png",
  ],
  option4: [
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 133.png",
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 131.png",
  ],
  option5: [
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 130.png",
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 131.png",
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 132.png",
  ],
  option6: [
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 125.png",
    "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/proects/Rectangle 126.png",
  ],
};

const Projects = () => {
  const [showBar, setShowbar] = useState(true);
  const handleToggleDetails = () => {
    setShowbar(!showBar);
  };

  //CHECKED BOx
  const [selectedBoxes, setSelectedBoxes] = useState({
    Risk: true,
    Water: true,
    Carbon: true,
    Groundwater: true,
    Carbonstock: true,
  });


  //handle SELECTDE BOX
  const [selectedOptions, setSelectedOptions] = useState([]);


  const handleCheckboxChange = (updatedCheckboxes) => {
    setSelectedBoxes(updatedCheckboxes);
   

    // Filter selected categories and update the OpenLayers map
    const selectedCategories = Object.keys(updatedCheckboxes).filter(
      (category) => updatedCheckboxes[category]
    );
    setSelectedOptions(selectedCategories);
    
  };

  const checkCategory = useMemo(
    () => ({
      Risk: {
        category: "Risk Assessment",
        color: "red",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/RiskAssesment.svg")
          .default,
      },
      Water: {
        category: "Water Stewardship",
        color: "blue",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/WaterStewardship.svg")
          .default,
      },
      Carbon: {
        category: "Carbon Offsetting",
        color: "green",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/CO2.svg")
          .default,
      },
      Groundwater: {
        category: "Groundwater",
        color: "orange",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/Groundwater.svg")
          .default,
      },
      Carbonstock: {
        category: "Carbon Stock Assessment",
        color: "purple",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/CarbonstockAssesmenmt.svg")
          .default,
      },
    }),
    []
  );
  // Initialize selectedOptions with the initially checked categories
  useEffect(() => {
    const initialSelectedCategories = Object.keys(selectedBoxes).filter(
      (category) => selectedBoxes[category]
    );
    setSelectedOptions(initialSelectedCategories);
  }, []);

  const [selectedOption, setSelectedOption] = useState("option1");

  const handleOptionChange = (event) => {
    const newOption = event.target.value;

    if (newOption === selectedOption) {
      setSelectedOption(null);
    } else {
      setSelectedOption(newOption);
    }
  };

  const isOptionSelected = (option) => {
    return option === selectedOption;
  };

  return (
    <>
      <div className="bg-projects">
        <div className="text-container">
          <a href="#scroll-section" style={{ textDecoration: "none" }}>
            <p className="scroll-text">SCROLL</p>
          </a>

          <div className="banner-text4">
            <h1 className="banner-heading"> Projects</h1>
            <p className="banner-para4">
              
              Building on a deep knowledge of our industry we deliver
               intelligent insights and practical solutions
            </p>
          </div>
        </div>
      </div>
      <div id="scroll-section">
        <div>
          <h1 className="project-text">
            Drawing from profound industry expertise, we provide both 
            astute insights and actionable solutions
          </h1>
        </div>
      </div>
      <div className="project-align">
        <div>
          <div className="project-content">
            <div
              className={`radio-option centering ${
                isOptionSelected("option1") ? "selected" : ""
              }`}
            >
              <label className="placingg">
                <input
                  type="radio"
                  name="projectOption"
                  value="option1"
                  checked={isOptionSelected("option1")}
                  onChange={handleOptionChange}
                  style={{ marginRight: "10px" }}
                />
                Climate & Natural Hazard Risk Analytics
              </label>
            </div>
            <div
              className={`radio-option centering ${
                isOptionSelected("option2") ? "selected" : ""
              }`}
            >
              <label className="placingg">
                <input
                  type="radio"
                  name="projectOption"
                  value="option2"
                  checked={isOptionSelected("option2")}
                  onChange={handleOptionChange}
                  style={{ marginRight: "10px" }}
                />
                Environmental Impact & Sustainability Analytics
              </label>
            </div>
            <div
              className={`radio-option centering ${
                isOptionSelected("option3") ? "selected" : ""
              }`}
            >
              <label>
                <input
                  type="radio"
                  name="projectOption"
                  value="option3"
                  checked={isOptionSelected("option3")}
                  onChange={handleOptionChange}
                  style={{ marginRight: "10px" }}
                />
                Ground Water Impact Assessment
              </label>
            </div>
            <div className="options-set">
              <div
                className={`radio-option centering ${
                  isOptionSelected("option4") ? "selected" : ""
                }`}
              >
                <label className="placingg">
                  <input
                    type="radio"
                    name="projectOption"
                    value="option4"
                    checked={isOptionSelected("option4")}
                    onChange={handleOptionChange}
                    style={{ marginRight: "10px" }}
                  />
                  GHG Emission & Environmental Social Governance
                </label>
              </div>
              <div
                className={`radio-option centering ${
                  isOptionSelected("option5") ? "selected" : ""
                }`}
              >
                <label>
                  <input
                    type="radio"
                    name="projectOption"
                    value="option5"
                    checked={isOptionSelected("option5")}
                    onChange={handleOptionChange}
                    style={{ marginRight: "10px" }}
                  />
                  Geospatial Tech Integrations
                </label>
              </div>
              <div
                className={`radio-option centering ${
                  isOptionSelected("option6") ? "selected" : ""
                }`}
              >
                <label>
                  <input
                    type="radio"
                    name="projectOption"
                    value="option6"
                    checked={isOptionSelected("option6")}
                    onChange={handleOptionChange}
                    style={{ marginRight: "10px" }}
                  />
                  Water & Land Risk & Sustainability
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="project-imagess">
          {selectedOption && (
            <div key={selectedOption}>
              {optionToImagesMap[selectedOption].map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index + 1} for ${selectedOption}`}
                  className="project-image"
                  style={{
                    marginLeft: "10px",
                    marginBottom: "10px",
                    marginRight: "30px",
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {/* <h2
        style={{
          color: "#bd0324",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        Our Presence
      </h2> */}
      <div className="parent">
        {/* Header content */}
        <div className="header-content">
          <button className="BannerToggleButton" onClick={handleToggleDetails}>
            {showBar ? (
              <i className="fas fa-times"></i>
            ) : (
              <i className="fas fa-bars"></i>
            )}
          </button>
          <h1
            className="dashboard-text"
            style={{
              color: "#bd0324",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            our presence
          </h1>
        </div>

        {/* Map and other content */}
        <div className="content-container" style={{marginBottom:'50px'}}>
          {/* SidebarDashboard */}
          {showBar && (
            <SidebarDashboard
              handleCheckboxChange={handleCheckboxChange}
              checkCategory={checkCategory}
              className="sidebar-dashboard"
            />
          )}

          {/* OpenLayersMap */}
          <OpenLayersMap
            showBar={showBar}
            selectedOptions={selectedOptions}
            checkCategory={checkCategory}
          />
        </div>
      </div>
    </>
  );
};

export default Projects;
