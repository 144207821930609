
import React, { useState, useEffect, useMemo } from "react";

import "./Home.css";
import OpenLayersMap from "./MapDashboard";
import SidebarDashboard from "./SidebarDashboard";
import "./MapDashboard.css";

import "./Dashboard.css";
const images = [
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/FirstStep.svg",
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/SecondStep.svg",
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/ThirdStep.svg",
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/FourthStep.svg",
  "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/FifthStep.svg"
];


const Home = () => {
  const [showBar, setShowbar] = useState(true);
  const handleToggleDetails = () => {
    setShowbar(!showBar);
  };

  //CHECKED BOx
  const [selectedBoxes, setSelectedBoxes] = useState({
    Risk: true,
    Water: true,
    Carbon: true,
    Groundwater: true,
    Carbonstock: true,
  });
 
  //handle SELECTDE BOX
const [selectedOptions, setSelectedOptions] = useState([]);
const handleCheckboxChange = (updatedCheckboxes) => {
setSelectedBoxes(updatedCheckboxes);
   

// Filter selected categories and update the OpenLayers map
const selectedCategories = Object.keys(updatedCheckboxes).filter(
  (category) => updatedCheckboxes[category]
 );
  setSelectedOptions(selectedCategories);
};

const checkCategory = useMemo(
    () => ({
      Risk: {
        category: "Risk Assessment",
        color: "red",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/RiskAssesment.svg")
          .default,
      },
      Water: {
        category: "Water Stewardship",
        color: "blue",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/WaterStewardship.svg")
          .default,
      },
      Carbon: {
        category: "Carbon Offsetting",
        color: "green",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/CO2.svg")
          .default,
      },
      Groundwater: {
        category: "Groundwater",
        color: "orange",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/Groundwater.svg")
          .default,
      },
      Carbonstock: {
        category: "Carbon Stock Assessment",
        color: "purple",
        path: require("../../src/assets/gcrs_images/dashBoard_icons/CarbonstockAssesmenmt.svg")
          .default,
      },
    }),
    []
  );
  // Initialize selectedOptions with the initially checked categories
  useEffect(() => {
    const initialSelectedCategories = Object.keys(selectedBoxes).filter(
      (category) => selectedBoxes[category]
    );
    setSelectedOptions(initialSelectedCategories);
  }, []);

  const [isClicked1] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);

  const [isClicked2] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const [isClicked3] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const [isClicked4] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  const [isClicked5] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);


  const [,setIsHovered6] = useState(false);

  const normalImageSrc1 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Screening.svg').default];

  const normalImageSrc2 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Scientific.svg').default];

  const normalImageSrc3 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Design.svg').default];
 
  const normalImageSrc4 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Restoration.svg').default];
 
  const normalImageSrc5 =
  [require('../../src/assets/gcrs_images/5_steps_redIcons/Monitoring.svg').default];


  // const products = [
  //   {
  //     id: 1,
  //     imageSrc:
  //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/lamas.png",
  //     alt: "lamas",
  //     description:
  //       "Delivers precise diagnostics of lacustrine systems and offers predictive analytics for lake health, encroachment detection, and water positivity, facilitating data-driven environmental solutions.",
  //     link: "https://lamas.co.in/",
  //   },
  //   {
  //     id: 2,
  //     imageSrc:
  //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/ce.png",
  //     alt: "ce",
  //     description:
  //       "A carbon footprint management platform that facilitates carbon credits trading and supports net-zero commitments through transparent carbon accounting aligned with ESG reporting frameworks.",
  //     link: "https://carbonexchange.co.in/",
  //   },
  //   {
  //     id: 3,
  //     imageSrc:
  //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/geosust.png",
  //     alt: "geosust",
  //     description:
  //       "Provides capability for geohazard/natural hazard risk identification and continuous monitoring, fostering proactive risk mitigation measures.",
  //     link: "https://geosust.com/",
  //   },
  //   {
  //     id: 4,
  //     imageSrc:
  //       "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/geocoast.png",
  //     alt: "geocoast",
  //     description:
  //       "Provides coastal zone deterioration tracking and oil spill impact assessment, facilitating data-driven strategy implementation for coastal resilience and alignment with ESG investment priorities.",
  //   },
  // ];

  const handleCardMouseEnter = (index) => {
    switch (index) {
      case 1:
        setIsHovered1(true);
        break;
      case 2:
        setIsHovered2(true);
        break;
      case 3:
        setIsHovered3(true);
        break;
      case 4:
        setIsHovered4(true);
        break;
      case 5:
        setIsHovered5(true);
        break;
      case 6:
        setIsHovered6(true);
        break;
      default:
        break;
    }
  };

  const handleCardMouseLeave = (index) => {
    switch (index) {
      case 1:
        setIsHovered1(false);
        break;
      case 2:
        setIsHovered2(false);
        break;
      case 3:
        setIsHovered3(false);
        break;
      case 4:
        setIsHovered4(false);
        break;
      case 5:
        setIsHovered5(false);
        break;
      case 6:
        setIsHovered6(false);
        break;
      default:
        break;
    }
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (currentImageIndex < images.length - 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex(prevIndex => prevIndex + 1);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [currentImageIndex]);
  return (
    <>
   <div className="banner-home">
      <div>
        <a href="#scroll-section" style={{ textDecoration: "none" }}>
          <p className="scroll-styles">SCROLL</p>
        </a>
      </div>
      <div>
      <h1 style={{marginLeft:'10px'}} className="main-heading">
      we restore the degraded landscapes for sustainable living and thriving businesses
     </h1>
     <div className="images-container">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="step"
              className={`image-alignment ${index === currentImageIndex ? 'visible' : ''} ${index === 4 ? 'fifth-image' : ''}`}
            />
          ))}
          <img
            src="https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/5_steps_vertical1.svg"
            alt="mobile step"
            className="mobile-image"
           
          />
        </div>
        <div className="button-container">
          <a href="/ContactUs" className="contact-button">Contact Us</a>
        </div>
      </div>


    </div>

    <div id="scroll-section" className="map-main-container">
  <div>
    <h2
      style={{
        color: "#bd0324",
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "bold",
        paddingTop: "1.5rem",
      }}
    >
       who are we
    </h2>
    <h2
      style={{
        fontSize: "20px",
        textAlign: "center",
        padding: "20px",
        marginTop: "20px",
        lineHeight: "1.5",
      }}
    >
      At GCRS, we stand at the forefront of innovation and expertise, dedicated to tackling the complex challenges of environmental sustainability.
      Our expert team not only offers exceptional consultancy and advisory services but also develops cutting-edge solutions and tools tailored to the unique needs of our diverse clientele. This includes government bodies, corporations, academic and research institutions, and a range of organisations committed to global sustainability, such as multi-lateral and bilateral funding partners, donor agencies, and non-governmental organisations.
    </h2>
    <h2
      style={{
        color: "#bd0324",
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "bold",
      }}
    >
      our approach
    </h2>
    <div className="row justify-content-center">
      {/* Card 1 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked1 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(1)}
          onMouseLeave={() => handleCardMouseLeave(1)}
        >
          <div className={`circle ${isHovered1 ? "hovered" : ""}`}>
            <img
              src={isClicked1 ? isHovered1 : normalImageSrc1}
              style={{ width: "50px" }}
              alt="Climate & Natural Hazard Risk Analytics"
              className="white-card"
            />
          </div>
          <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",position: "relative",display: "inline-block"}}className="hover-effect-home">
          <span style={{fontWeight: "bold",fontSize: "16px"}}> Screening & Diagnosing</span> the Health of landscapes
          </h3>
        </div>
      </div>

      {/* Card 2 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked2 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(2)}
          onMouseLeave={() => handleCardMouseLeave(2)}
        >
          <div className={`circle ${isHovered2 ? "hovered" : ""}`}>
            <img
              src={isClicked2 ? isHovered2 : normalImageSrc2}
              style={{ width: "50px" }}
              alt="Environmental Impact & Sustainability Analytics"
              className="white-card"
            />
          </div>
          <h3 
          style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}
           className="hover-effect-home"
             >
           Detailed
            <span style={{ fontWeight: "bold", fontSize: "16px" }}> Scientific Assessment</span>
  
</h3>
          </div>
      </div>

      {/* Card 3 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked3 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(3)}
          onMouseLeave={() => handleCardMouseLeave(3)}
        >
          <div className={`circle ${isHovered3 ? "hovered" : ""}`}>
            <img
              src={isClicked3 ? isHovered3 : normalImageSrc3}
              style={{ width: "50px" }}
              alt="Ground Water Impact Assessment"
              className="white-card"
            />
          </div>
          <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}className="hover-effect-home">
           <span style={{ fontWeight: "bold" ,fontSize: "16px"}}>Design</span> Restoration & Management Plans
          </h3>
        </div>
      </div>

      {/* Card 4 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked4 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(4)}
          onMouseLeave={() => handleCardMouseLeave(4)}
        >
          <div className={`circle ${isHovered4 ? "hovered" : ""}`}>
            <img
              src={isClicked4 ? isHovered4 : normalImageSrc4}
              style={{ width: "50px" }}
              alt="Card 4"
              className="white-card"
            />
          </div>
          <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}className="hover-effect-home">
           <span style={{ fontWeight: "bold" ,fontSize: "16px"}}>Restoration</span> of Landscapes
          </h3>
          </div>
      </div>

      {/* Card 5 */}
      <div className="col-lg-2 col-md-4 col-sm-6 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          className={`container1 ${isClicked5 ? "hovered" : ""}`}
          onMouseEnter={() => handleCardMouseEnter(5)}
          onMouseLeave={() => handleCardMouseLeave(5)}
        >
          <div className={`circle ${isHovered5 ? "hovered" : ""}`}>
            <img
              src={isClicked5 ? isHovered5 : normalImageSrc5}
              style={{ width: "50px" }}
              alt="Geospatial Tech Integrations"
              className="white-card"
            />
          </div>
          <h3 style={{fontSize: "14px",padding: "10px",textAlign: "center",paddingTop: "10px",display: "inline-block"}}className="hover-effect-home">
             Continuous <span style={{ fontWeight: "bold" ,fontSize: "16px"}}>Monitoring</span> of Landscapes
          </h3>

        </div>
      </div>
    </div>
    <div>
                {/* <h2
                  style={{
                    color: "#BD0324",
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  our solutions
                </h2>
                <div className="image-container">
                  <div className="row justify-content-center">
                    {products.map((product) => (
                      <div
                        key={product.id}
                        className="col-lg-3 col-md-3 col-sm-6 mb-4"
                        
                      >
                        <div
                          style={{
                            height: "250px",
                            width: "100%",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <a className="fusion-no-lightbox" href={product.link}>
                            <img
                              decoding="async"
                              src={product.imageSrc}
                              alt={product.alt}
                              style={{
                                width: "14.375rem",
                                height: "5rem",
                                
                              }}
                              className="img-fluid"
                            />
                          </a>
                          <p
                            style={{
                              color: "#282222",
                              fontSize: "0.875rem",

                              fontWeight: "bold",
                              
                             
                            }}
                          >
                            {product.description}
                          </p>
                          {product.link && (
                            <a
                              href={product.link}
                              id="accred_link"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{}}
                            >
                              ({product.link})
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
    <div className="parent">
      {/* Header content */}
      <div className="header-content">
        <button
          className="BannerToggleButton"
          onClick={handleToggleDetails}
        >
          {showBar ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-bars"></i>
          )}
        </button>
        <h1
          className="dashboard-text"
          style={{
            color: "#bd0324",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            marginRight:'40px'
          }}
        >
          our presence
        </h1>
      </div>

      {/* Map and other content */}
      <div className="content-container" style={{ marginBottom: '50px' }}>
        {/* SidebarDashboard */}
        {showBar && (
          <SidebarDashboard
            handleCheckboxChange={handleCheckboxChange}
            checkCategory={checkCategory}
            className="sidebar-dashboard"
          />
        )}

        {/* OpenLayersMap */}
        <OpenLayersMap
          showBar={showBar}
          selectedOptions={selectedOptions}
          checkCategory={checkCategory}
        />
      </div>
    </div>
  </div>
</div>
</div>


    </>
  );
};
export default Home;
